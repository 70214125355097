import React from 'react'

import {VscGitPullRequestCreate} from 'react-icons/vsc'
import {BsPersonBoundingBox, BsCodeSlash} from 'react-icons/bs'
import { FaCheck } from "react-icons/fa6";
import { GiHamburgerMenu } from 'react-icons/gi'

import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
  } from "@material-tailwind/react";

import CheckBg_1 from '../../resources/checker-black-white-bg-25_shadow_opacity.png'
import CheckBg_2 from '../../resources/checker-pink-white-bg-10_shadow_opacity.png'
import CheckBg_3 from '../../resources/checker-pink-white-bg-10_shadow_opacity-white_circle.png'
import CheckBg_4 from '../../resources/big-checker-no-bg.png'

import FrHeader from '../../components/fr/FrHeader'

import Logo from '../../resources/logos/Pro-Web-Solutions-logo-1000x300-white-bg.png'
import { Link } from 'react-router-dom'


import UkFlag from '../../resources/uk-flag.jpg'


export default function Test() {
  return (
    <div className=''>

        <div id='hero' className='heroBg'>

            {/* <div id='applied bg' className='heroBg absolute z-0 top-0 w-full h-full'>
            </div> */}
            
            <div id='content' className=''>
                <header className=''>
                    {/* <div className='absolute top-0 z-[-10] 
                        bg-white
                        w-screen h-[125px]' /> */}
                    <div className='flex justify-center md:flex md:justify-between'>
                        <div className=' md:ml-5'>
                            <a href={'/#/fr/home'}>
                                <img src={Logo} alt='Pro Web Solutions Home' className='w-[200px]'></img>
                            </a>
                        </div>
                        <div className='flex ml-auto gap-10 md:mx-5 md:my-auto'>
                            <Link to={'/'} className='md:mr-20 my-auto'>
                                <img src={UkFlag} className='mx-auto rounded-full h-[50px] w-[50px]' alt=''/>
                            </Link>
                            {/* Menu - Dropdown */}
                            <div className='my-auto'>
                                <NewDropDown/>
                            </div>
                        </div>
                    </div>
                </header>

                <div className='grid justify-center text-center pt-24 pb-10 text-white px-5 sm:px-20 md:px-32 lg:px-40 xl:px-60'>
                    <h1 className='text-3xl font-bold pb-5 px-5 md:text-5xl lg:text-6xl'>
                        Augmentez votre présence en ligne dès <span className='bg-clip-text text-transparent bg-gradient-to-r from-[var(--colorPrimary)] to-[var(--colorSecondary)]'>maintenant</span>   
                    </h1>
                    <div className=''>
                        <p className='text-xl text-gray-300 lg:text-2xl'>
                            Pro Web Solutions vous aide élever votre entreprise à un nouveau niveau.
                        </p>
                    </div>
                    <div className='pt-5 flex justify-center'>
                        <Link className='bg-[var(--colorPrimary)] p-3'>
                            <span className='text-xl font-bold lg:text-2xl'>Découvrez plus</span>
                        </Link>
                    </div>
                </div>
            </div>

        </div>


        <div className='pt-5'>
            <Features></Features>
        </div>

    </div>
  )
}


const Features = () => {
    const FeatureCard = ({Title,Text}) => {
        return(
            <div className='flex text-white overflow-visible'>
                <div className='pt-5'>
                    <div className='text-xl m-auto bg-blue-700 p-2 rounded-full'>
                            {<FaCheck/>}
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='border-r-[0.15rem] border-blue-700 h-[175px]'>
                        </div>
                        <div className=''>
                        </div>
                    </div>
                    
                </div>

                <div className='grid justify-center p-5 bg-[var(--colorGray)] rounded-xl mb-10'>
                    <h2 className=' font-bold text-2xl md:text-4xl'>
                        {Title}
                    </h2>
                    <div className=''>
                        <p className = 'text-lg text-gray-300'>
                            {Text}
                        </p>
                    </div>
                </div>
                

            </div>
        )
    }

    return(
        <div className='pb-5 px-10 md:pb-10 md:px-20 lg:px-32 2xl:px-60  '>
            <div className='text-white text-center mx-3 '>
                <div className='pb-5 flex justify-center'>
                    <div className='grid pr-2 lg:pr-10'>
                        <div className=''></div>
                        <div className='border-t-[0.15rem] border-[var(--colorGray)] w-[25px] md:w-[200px]'></div>
                    </div>
                    <p className='text-gray-300 text-xs text-center uppercase tracking-widest'>
                        Augmentez votre business infiniment
                    </p>
                    <div className='grid pl-2 lg:pl-10'>
                        <div className=''></div>
                        <div className='border-t-[0.15rem] border-[var(--colorGray)] w-[25px] md:w-[200px] '></div>
                    </div>
                </div>

                <h2 className='text-3xl font-bold mb-5 bg-clip-text text-transparent bg-gradient-to-br from-blue-700 to-[var(--colorPrimary)]
                                md:text-4xl'>
                        Gagnez du temps grâce à la technologie ! 
                </h2>

                <div>
                    <p className='text-gray-300 text-xl pb-3 lg:mx-32'>
                        Pro Web Solutions vous offre l'opportunité d'automatiser les tâches chronophages.
                    </p>
                </div>

            </div>
            <div className='grid justify-center mx-3 2xl:mx-40 '>
                <FeatureCard Title={"Stratégie"} Text={"Une stratégie centrée sur le client alimente notre processus, garantissant que le produit final s'aligne parfaitement sur vos objectifs uniques."}/>
                <FeatureCard Title={"Concept"} Text={"Grâce à un concept d'entreprise clairement défini, nos projets de développement web sont ciblés et livrent des produits qui s'alignent parfaitement sur vos objectifs."}/>
                <FeatureCard Title={"Développement"} Text={"Alors que nous entamons le développement, votre concept s'épanouit en un produit abouti, minutieusement peaufiné et testé pour offrir une expérience utilisateur exceptionnelle."}/>
            </div>
        </div>
    )
}


const NewDropDown = () => {
    return(
        <Menu animate={{
            mount: { y:0 },
            unmount: { y:125 }
          }}>
            <MenuHandler>
              <Button className='text-gray-300 text-3xl bg-transparent shadow-transparent'>
                <GiHamburgerMenu/>
              </Button>
            </MenuHandler>
            <MenuList className='grid px-10 py-2 rounded-none
              bg-[var(--colorGray)] border-0
              h-screen
              '>
              <div className='grid mb-auto gap-10 pt-10'>
                <MenuItem className='dropdown-button'>
                    <Link to={'/fr/home'} className='px-20 py-1'>Accueil</Link>
                </MenuItem>
                <MenuItem className='dropdown-button'>
                    <Link to={'/fr/about'} className='px-20 py-1'>À propos</Link>
                </MenuItem>
                <MenuItem className='dropdown-button'>
                    <Link to={'/fr/contact'} className='px-20 py-1'>Contact</Link>
                </MenuItem>
                <MenuItem className='dropdown-button'>
                    <Link to={'/fr/products'} className='px-20 py-1'>Services</Link>
                </MenuItem>
              </div>
            </MenuList>
          </Menu>
    )
}