import React from 'react'

import { GiHamburgerMenu } from 'react-icons/gi'

import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
  } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
   
  export default function DropdownMenu() {
    return (
      <Menu animate={{
        mount: { y:0 },
        unmount: { y:125 }
      }}>
        <MenuHandler>
          <Button className='text-black text-3xl bg-transparent shadow-transparent'>
            <GiHamburgerMenu/>
          </Button>
        </MenuHandler>
        <MenuList className='grid px-10 py-2 rounded-none
          bg-[var(--colorBlack-50)]
          '>
          <MenuItem className='dropdown-button'>
            <Link to={'/en/home'} className='px-20 py-1'>Home</Link>
          </MenuItem>
          <MenuItem className='dropdown-button'>
            <Link to={'/en/about'} className='px-20 py-1'>About Us</Link>
          </MenuItem>
          <MenuItem className='dropdown-button'>
            <Link to={'/en/contact'} className='px-20 py-1'>Contact</Link>
          </MenuItem>
          <MenuItem className='dropdown-button'>
            <Link to={'/en/products'} className='px-20 py-1'>Products</Link>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }