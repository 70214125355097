import React from 'react'

import Image from '../../resources/20943953.jpg'
import ContactForm from '../../components/fr/FrContactForm'
import FrHeader from '../../components/fr/FrHeader'

export default function FrContactPage() {
  return (
    <div>
      <FrHeader/>
      <div className='grid bg-[var(--colorSecondary)]'>
        <div className='grid mx-auto my-10 bg-white pt-5 pb-20
            lg:px-20'>
          <div className='max-w-[750px]'>
            <img src={Image} alt='Contact Us img'></img>
          </div>
          <div className=''>
            <div className=''>
              <TopService/>
            </div>
            <div className=''>
              <ContactForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  )
}


const TopService = () => {
  return(
      <div className=''>
          <div className='grid mx-10
              md:container md:mx-auto'>
              <h3 className='uppercase text-xl font-bold mb-2
                  text-[var(--colorPrimary)]'>NOS SERVICES PRINCIPAUX</h3>
              <ul className='my-5'>
                <li>✔ Réponse en <span className='font-medium'>24h</span> heures</li>
                <li>✔  Devis en ligne <span className='text-green-600 font-medium'>Gratuit</span></li>
                <li>✔ <span className='font-medium'>Transparence</span> </li>
              </ul>
          </div>
      </div>
  )
}