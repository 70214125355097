import React from 'react'

import Logo from '../../resources/logos/Pro-Web-Solutions-logo-with-text-500x500-white-bg.png'
import { Link } from 'react-router-dom'
import DropdownMenu from './FrDropdownMenu'

import UkFlag from '../../resources/uk-flag.jpg'

export default function FrHeader() {


  return (
    <header className='md:sticky md:top-0 md:z-10 '>
        {/* <div className='absolute top-0 z-[-10] 
            bg-white
            w-screen h-[125px]' /> */}
        <div className='bg-white grid justify-center md:flex md:justify-between'>
            <div className='md:ml-5'>
                <a href={'/#/fr/home'}>
                    <img src={Logo} alt='Pro Web Solutions Home' className='w-[125px] '></img>
                </a>
            </div>
            <div className='mx-auto md:mx-5 md:my-auto grid md:grid-flow-col'>
                {/* <Link to={'/'} className='md:mr-20'>
                    <img src={UkFlag} alt='' className='mx-auto rounded-full h-[50px] w-[50px]' />
                </Link> */}
                {/* Menu - Dropdown */}
                <DropdownMenu/>
            </div>
        </div>
    </header>
  )
}




