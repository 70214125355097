import React from 'react'
import {Link} from 'react-router-dom'

import {VscGitPullRequestCreate} from 'react-icons/vsc'
import {BsPersonBoundingBox, BsCodeSlash} from 'react-icons/bs'

import HeroImage from '../../resources/Wavy_Bus-06_Single-05-with-shadows-1500x1500-nb.png'
import Image1 from '../../resources/20943839-1500x1500-nb.png'

import AboutImage from '../../resources/3323882.jpg'
import ContactForm from '../../components/fr/FrContactForm'

import FrHeader from '../../components/fr/FrHeader.jsx'

export default function FrHomePage() {
  return (
    <div>
        <FrHeader/>
        <Hero/>
        <AboutUs/>
        <Features/>
        <CallingUserToContact/>
        <GetInTouch/>
    </div>
  )
}




const Hero = () => {
    const HeroButton = ({To,Text}) => {
        return(
            <Link to={To} className="button-type-1">{Text}</Link>
        )
    } 

    return(
        <div className='heroHolder'>
            <div className=' container mx-auto
                px-10 py-10
                sm:px-20
                md:grid md:grid-cols-2 md:my-auto
                lg:px-40'>
                <div id='text' className='text-white font-ProWebS'>
                    <h1 className='font-bold text-5xl sm:text-6xl
                        xl:text-7xl'>Valoriser Votre <span id='demotext' >Présence</span> Online</h1>
                    <h2 className='text-3xl py-5
                        xl:text-4xl xl:pr-20'>Élevez votre entreprise avec une <span className='underline'>technologie puissante</span> et un <span className='underline'>design</span> esthétique</h2>
                    <div className='grid mt-10 mb-5 justify-start'>
                        <HeroButton To={"/fr/products"} Text={"Découvrir"} />
                    </div>
                </div>
                <div id='photo' className=''>
                    <img src={HeroImage} alt='Pro Web Solutions website type' className=''></img>
                </div>
            </div>
        </div>
    )
}

const AboutUs = () => {
    const AboutButton = ({To,Text}) => {
        return(
            <Link to={To} className="button-type-1">{Text}</Link>
        )
    }
    const InfoCard = ({Photo,Title,Text}) => {
        return(
            <div className='font-ProWebS container mx-auto lg:my-32'>
                <div className='grid mx-10 py-10
                    lg:grid-cols-2'>
                    <div className='my-5'>
                        <img src={Photo} alt='about ProWebSolutions' className='w-[350px] h-[350px] mx-auto
                            md:w-[450px] md:h-[450px]'></img>
                    </div>
                    <div className='p-5 text-white'>
                        <h2 className='text-[var(--colorPrimary)] font-bold text-3xl'>{Title}</h2>
                        <p className='text-[var(--colorBlack)] font-medium mt-10 text-lg'>{Text}</p>
                        <div className='my-10'>
                            <AboutButton To={'/about'} Text={'Read More'}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className='mb-20'>
            {/* <InfoCard Photo={AboutImage} Title={"Your trusted partner in creating exceptional web solutions."} Text={"Based in Belgium, our company is dedicated to delivering top-notch websites to businesses of all sizes. We pride ourselves on utilizing cutting-edge technologies to offer the best possible solutions to our clients.But our commitment doesn't stop there. We understand the importance of scalability in today's fast-paced digital world. That's why we work with cloud-based solutions to make your web presence easily scalable. Whether your business experiences rapid growth or sudden surges in traffic, our cloud-based approach ensures that your website performs optimally, maintaining a seamless user experience for your audience."} /> */}
            <div className='font-ProWebS container mx-auto lg:my-32'>
                <div className='grid mx-10 py-10
                    lg:grid-cols-2'>
                    <div className='my-5'>
                        <img src={AboutImage} alt='about ProWebSolutions' className='w-[350px] h-[350px] mx-auto
                            md:w-[450px] md:h-[450px]'></img>
                    </div>
                    <div className='p-5 text-white'>
                        <h2 className='text-[var(--colorPrimary)] font-bold text-3xl'>Votre partenaire de confiance pour créer des solutions web exceptionnelles.</h2>
                        <p className='text-[var(--colorBlack)] font-medium mt-10 text-lg'>
                        Basée en Belgique, notre entreprise est dédiée à la création de sites web de premier ordre pour des entreprises de toutes tailles. 
                        Nous sommes fiers d'utiliser des technologies de pointe pour offrir les meilleures solutions possibles à nos clients.
                            <br/><br/>
                            Mais notre engagement ne s'arrête pas là.
                             Nous comprenons l'importance de la scalabilité dans le monde numérique d'aujourd'hui en constante évolution.
                              C'est pourquoi nous travaillons avec des solutions basées sur le cloud pour rendre votre présence en ligne facilement scalable.
                               Que votre entreprise connaisse une croissance rapide ou des pics soudains de trafic,
                                notre approche basée sur le cloud garantit que votre site web fonctionne de manière optimale,
                                 offrant une expérience utilisateur fluide à votre audience.
                        </p>
                        <div className='my-10'>
                            <AboutButton To={'/fr/about'} Text={'Lire Plus'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const CallingUserToContact = () => {

    return(
        <div className='font-ProWebS container mx-auto my-5'>
            <div className='grid grid-flow-row px-10 space-y-5
                md:grid-rows-1 md:grid-cols-2 md:space-x-4'>
                <div className='px-5 py-4 bg-primary
                    md:px-10 md:py-6
                    lg:px-20'>
                    <h1 className='font-bold text-5xl text-white
                        md:text-6xl
                        lg:text-7xl
                        xl:text-8xl'>
                        Réalisez votre vision.
                    </h1>
                </div>
                <div className='grid
                    md:space-y-4'>
                    <div className='self-end grid px-10 py-5 bg-[var(--colorBlack-15)]
                        lg:mr-10 lg:mt-32'>
                        <div>
                            <h2 className='font-medium text-3xl
                                md:text-4xl
                                lg:text-5xl'>Trouvez vos solutions web dès aujourd'hui</h2>
                        </div>
                        <div className='text-white my-10'>
                            <Link to='/fr/products' className="button-type-1">Voir plus</Link>
                        </div>
                    </div>
                    <div className='grid'>
                        <img src={Image1} alt='Pro Web Solutions contact us' className='lg:w-[70%] '></img>
                    </div>
                </div>
            </div>
            <div className='self-end'>
                
            </div>
        </div>
    )
}


const Features = () => {
    const FeatureCard = ({Icon,Title,Text}) => {
        return(
            <div className=' grid py-5 px-5
             bg-[var(--colorSecondary-75)] 
             text-white
             w-[20rem]
             md:w-[14.5rem]
             xl:w-[400px]
             2xl:px-0'>
                <div className='text-4xl justify-self-center
                    xl:text-6xl my-auto'>
                    {<Icon/>}
                </div>
                <div className='text-center text-[var(--colorPrimary)]'>
                    <h2 className='font-bold text-2xl
                        2xl:text-4xl'>{Title}</h2>
                </div>
                <div className='text-center'>
                    <p className='text-lg m-2 h-[150px]
                        md:h-[250px]
                        xl:h-[150px]
                        2xl:text-xl 2xl:mx-10'>
                        {Text}
                    </p>
                </div>

            </div>
        )
    }

    return(
        <div className='font-ProWebS grid
            bg-secondary 
            py-10'>
            <div className='text-white text-center mx-5'>
                <h3 className='text-2xl py-3 
                    md:text-3xl'>
                    Fonctionnalités</h3>
                <div className='border-b-[0.35rem] border-white mx-20 mb-5' />
                <h2 className='text-4xl font-bold mb-10
                    lg:text-4xl'>Optimiser nos produits grâce à une approche axée sur le design</h2>
            </div>
            <div className='grid justify-center
                md:grid-flow-col'>
                <FeatureCard Icon={VscGitPullRequestCreate} Title={"Stratégie"} Text={"Une stratégie centrée sur le client alimente notre processus, garantissant que le produit final s'aligne parfaitement sur vos objectifs uniques."}/>
                <FeatureCard Icon={BsPersonBoundingBox} Title={"Concept"} Text={"Grâce à un concept d'entreprise clairement défini, nos projets de développement web sont ciblés et livrent des produits qui s'alignent parfaitement sur vos objectifs."}/>
                <FeatureCard Icon={BsCodeSlash} Title={"Développement"} Text={"Alors que nous entamons le développement, votre concept s'épanouit en un produit abouti, minutieusement peaufiné et testé pour offrir une expérience utilisateur exceptionnelle."}/>
            </div>
        </div>
    )
}


const GetInTouch = () => {
    const GetInTouchCard = () => {
        return(
            <div className=''>
                <div className='grid mx-10
                    md:container md:mx-auto
                    lg:w-96'>
                    <h3 className='uppercase font-bold text-3xl
                        text-[var(--colorPrimary)]'>Contactez-nous</h3>
                    <h5 className='font-medium italic my-5'>Notre priorité est la fiabilité et la rentabilité, garantissant votre confort tout au long du parcours.</h5>
                    <p className='py-2'>Guidés par nos valeurs, nous nous appuyons sur les piliers de la fiabilité et de la rentabilité. Avant tout, votre satisfaction demeure notre principale préoccupation. Des réponses rapides renforcent la confiance grâce à des interactions transparentes.</p>
                    <div id='social-media-icons'>
                        
                    </div>
                </div>
            </div>
        )
    }

    const CallUs = () =>{
        return(
            <div className=''>
                <div className='grid mx-10
                    md:container md:mx-auto'>
                    <h3 className='uppercase text-xl font-bold mb-2
                        text-[var(--colorPrimary)]'>CALL US </h3>
                    <h5>1 (234) 567-891</h5>
                </div>
            </div>
        )
    }

    const TopService = () => {
        return(
            <div className=''>
                <div className='grid mx-10
                    md:container md:mx-auto'>
                    <h3 className='uppercase text-xl font-bold mb-2
                        text-[var(--colorPrimary)]'>NOS SERVICES PRINCIPAUX</h3>
                    <ul className='my-5'>
                        <li>✔  Réponse en <span className='font-medium'>24h</span> heures</li>
                        <li>✔  Devis en ligne <span className='text-green-600 font-medium'>gratuit</span></li>
                        <li>✔ <span className='font-medium'>Transparence</span> </li>
                    </ul>
                </div>
            </div>
        )
    }

    return(
        <div className='font-ProWebS flex flex-col gap-5 my-20
            lg:container lg:grid lg:grid-flow-col lg:mx-auto'>
            <GetInTouchCard/>
            <div className='flex flex-col gap-5'>
                {/* <CallUs/> */}
                <TopService/>
            </div>
            <ContactForm/>
        </div>
    )
}

